const color = {
    firstColor: '#2583e5',
    secondColor: '#18022f',
    thirdColor: '#161d29',
    bannerText: '#573927',
    scrollbarBg: '#1e8bf172',

    sidebarBg: '#ffffff',
    sidebarText: '#1e8bf1',
    sidebarButton: 'linear-gradient(35deg, #1e8bf1 1%, #b365b6 100%)',
    sidebarButtonText: '#ffffff',

    webinarCardBorder: 'linear-gradient(35deg, #1e8bf1 1%, #b365b6 100%)',


    headerBg: 'white',
    headerText: '#ffffff',
    footerBg: 'linear-gradient(35deg, #24dcfb 1%, #2c0782 100%)',
    footerText: '#ffffff',
}

export default color;